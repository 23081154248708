import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DecimalPipe } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, finalize, takeUntil, throttleTime } from 'rxjs/operators';
import { COLORS } from 'app/shared/constants/colors.constants';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetSegment, BudgetSegmentAmount } from 'app/shared/types/segment.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { FlatSelectOption } from 'app/shared/components/flat-select/flat-select.component';
import { BudgetTableService } from '../../services/budget-table.service';
import { BudgetSettingsPageService } from '../budget-settings-page/budget-settings-page.service';
import {
  BudgetTableChangeEvent,
  BudgetTableActionType,
  BudgetTableRecord,
  BudgetTableRecordType,
  BudgetTableGesturesDataSource
} from './budget-table.types';
import { BudgetTableValidationService } from '../../services/budget-table-validation.service';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { BudgetAllocationCellGesturesEvent } from 'app/budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { BudgetAllocationTopupAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-topup-action';
import { Currency } from 'app/shared/types/currency.interface';
import { BudgetAllocationMoveAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-move-action';
import { BudgetStatus } from 'app/shared/types/budget-status.type';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { BudgetSegmentNameComponent } from '../budget-segment-name/budget-segment-name.component';
import { BudgetTableRecordInteractionsService } from '../../services/budget-table-record-interactions.service';
import { BudgetTableSelectedRecords } from '../../types/budget-table-selection.types';
import { BudgetTableContextMenuService } from '../../services/budget-table-context-menu.service';
import { BudgetAllocationActionTooltipContext } from 'app/budget-allocation/components/budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';
import { CheckboxValue } from 'app/shared/enums/checkbox-value.enum';
import { TimeframeProcessingComponent } from '@shared/components/abstract/timeframe-processing.component';
import { CompanyUserStatus } from '@shared/types/company-user-do.interface';
import { ModalContext } from '../../../shared/components/modal-with-icon-header/modal-with-icon-header.component';
import { IconHeaderModalService } from '../../../shared/services/modal-with-icon-header.service';
import { Budget } from '../../../shared/types/budget.interface';
import { EditBudgetModalService } from '../../../shared/services/edit-budget-modal.service';

@Component({
  selector: 'budget-table',
  templateUrl: './budget-table.component.html',
  styleUrls: ['./budget-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BudgetAllocationActionsService]
})
export class BudgetTableComponent extends TimeframeProcessingComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() tableData: BudgetTableRecord[];
  @Input() currency: Currency;
  @Input() isCegBudget = false;
  @Input() getForecastStatus = false;
  @Input() budget: Budget;

  @Output() onChange = new EventEmitter<BudgetTableChangeEvent>();
  @Output() onSelectionChanged = new EventEmitter<BudgetTableSelectedRecords>();

  @ViewChild('tableParent') tableParent: ElementRef;
  @ViewChild('tableRoot') tableRoot: ElementRef;
  @ViewChild('segmentMenuTrigger') segmentMenuTrigger: MatMenuTrigger;
  @ViewChild('groupMenuTrigger') groupMenuTrigger: MatMenuTrigger;
  @ViewChildren('segmentNameCmp') segmentNameComponents: QueryList<BudgetSegmentNameComponent>;
  @ViewChild('budgetAllocationCell') budgetAllocationCell: ElementRef;

  private readonly destroy$ = new Subject<void>();
  private readonly loadingFinished$ = new Subject<void>();
  private readonly ERROR_MESSAGE = {
    FAILED_TO_UPDATE_NAME: 'Failed to update segment name',
    FAILED_TO_UPDATE_OWNER: 'Failed to update segment owner',
    FAILED_TO_UPDATE_ALLOCATED: 'Failed to update allocated amount',
    FAILED_TO_UPDATE_PROJECTED: 'Failed to update projected amount',
    NO_REMAINING_TO_COVER_DIFF: `There is no global “Remaining” budget to cover this over budget amount -
      you can change the budgeted amount manually or drag & drop the green remaining budget amount
      from another segment/timeframe here to cover the difference
    `
  };
  private budgetCueHiddenKey = 'budgetCue';
  private resizeObserver: ResizeObserver;
  private dragOverScrollAnimation: number;
  private dragOverScrollDx = 0;
  private contextMenuHandlers = {
    [BudgetTableActionType.SegmentsGroup]: () => this.handleGroupSegmentsAction(),
    [BudgetTableActionType.SegmentsUngroup]: () => this.handleUngroupSegmentsAction(),
    [BudgetTableActionType.SegmentsMove]: (group) => this.handleMoveToGroupAction(group),
    [BudgetTableActionType.GroupDuplicate]: () => this.handleDuplicateGroupAction(),
    [BudgetTableActionType.SegmentDuplicate]: () => this.handleDuplicateAction(),
    [BudgetTableActionType.SegmentDelete]: () => this.handleDeleteAction(),
  };

  public RecordType = BudgetTableRecordType;
  public tableDomState = {
    rootWidth: 0,
    scrollableX: false,
    scrollableY: false
  };
  private isModalOpen = false;
  public showBudgetCue: boolean;
  public showProjected = true;
  public showForecast = false;
  public isLoading = true;
  public rippleColor = `${COLORS.PRIMARY}40`;
  public projectedTooltip = `Projected Budget captures how much was originally allocated for each segment,
  independent of the numbers that were entered/updated per timeframe.
  It can come in handy later to compare original plans to distribute funds vs current budgeting.`;
  public readonly SelectionValue = CheckboxValue;
  public tooltipContext = BudgetAllocationActionTooltipContext.BudgetPage;

  private static getDataSourceAmount(dataSource: BudgetTableGesturesDataSource): number {
    return dataSource.record?.values?.allocated[dataSource.timeframe?.id] || 0;
  }

  constructor(
    private readonly budgetTableService: BudgetTableService,
    private readonly budgetTableValidationService: BudgetTableValidationService,
    private readonly budgetPageService: BudgetSettingsPageService,
    private readonly budgetTableRecordInteractions: BudgetTableRecordInteractionsService,
    private readonly budgetTableContextMenuService: BudgetTableContextMenuService,
    private readonly utilityService: UtilityService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly gesturesManager: BudgetAllocationActionsService<BudgetTableGesturesDataSource>,
    private readonly decimalPipe: DecimalPipe,
    private readonly zone: NgZone,
    private modalService: IconHeaderModalService,
    private focusHandler: EditBudgetModalService
  ) {
    super();
  }

  get selectOwners$() {
    return this.budgetPageService.ownerSelectOptions$;
  }

  get grandTotal() {
    return this.budgetTableService.grandTotal;
  }

  get tableExpenseTotals() {
    return this.budgetTableService.tableExpenseTotals;
  }

  get remainingBudget() {
    return this.budgetTableService.remainingBudget;
  }

  get sortableFields() {
    return this.budgetTableService.sortableFields;
  }

  get appliedSorting() {
    return this.budgetTableService.appliedSorting;
  }

  get editableBudget() {
    return this.budget?.status !== BudgetStatus.Reference;
  }

  get selectionState() {
    return this.budgetTableRecordInteractions.selectionState;
  }

  get togglingState() {
    return this.budgetTableRecordInteractions.togglingState;
  }

  get segmentContextMenu() {
    return this.budgetTableContextMenuService.segmentContextMenu;
  }

  get groupContextMenu() {
    return this.budgetTableContextMenuService.groupContextMenu;
  }

  get groupsList() {
    return this.budgetTableService.groupsList;
  }

  get globalDragStarted() {
    return this.gesturesManager.isDragging;
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(document, 'dragover')
        .pipe(
          filter(() => !!this.globalDragStarted),
          throttleTime(500),
          takeUntil(this.destroy$)
        )
        .subscribe(
          (event: DragEvent) => {
            this.handleGlobalDragOver(event);
          }
        );
    });


    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(resizeEntry => {
        if (resizeEntry.target === this.tableRoot.nativeElement) {
          this.tableDomState.rootWidth = resizeEntry.contentRect.width;
        }

        if (resizeEntry.target === this.tableParent.nativeElement) {
          this.tableDomState.scrollableX = resizeEntry.target?.clientWidth < resizeEntry.target?.scrollWidth;
          this.tableDomState.scrollableY = resizeEntry.target?.clientHeight < resizeEntry.target?.scrollHeight;
        }
      });
      this.cdRef.detectChanges();
    });

    this.budgetPageService.ownersList$
      .pipe(
        filter(list => list != null),
        takeUntil(this.destroy$)
      )
      .subscribe(ownersList => {
        this.budgetTableService.processOwnersList(ownersList);
        this.cdRef.detectChanges();
      });

    this.loadingFinished$.asObservable()
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          setTimeout(() => {
            this.isLoading = false;
            this.cdRef.detectChanges();
          }, 500);
        }
      );

    this.budgetPageService.onDataLoading$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.isLoading = true;
          this.cdRef.detectChanges();
        }
      );

    this.initBudgetCue();
  }

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.tableRoot?.nativeElement);
    this.resizeObserver.observe(this.tableParent?.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.tableData && this.tableData) {
      this.resetState();
      this.onTableDataReady();
    }
    if(changes?.getForecastStatus?.currentValue && this.budget?.id) {
      this.budgetPageService.loadSegmentsList(this.budget?.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeObserver.unobserve(this.tableRoot?.nativeElement);
    this.resizeObserver.unobserve(this.tableParent?.nativeElement);
  }

  private initBudgetCue(): void {
    setTimeout(() => {
      this.showBudgetCue = !localStorage.getItem(this.budgetCueHiddenKey);
      this.cdRef.detectChanges();
    }, 2000);
  }

  private returnOwnerLabelByType(owner, labelType): string {
    let returnString: string;
    if (owner.name && owner.status) {
      switch (labelType) {
        case 'name':
          returnString = `${owner?.name}`
          break;
        case 'initial':
          returnString = `${owner?.initials}`
          break;
      }
      if (owner?.status === CompanyUserStatus.Disabled || owner?.status === CompanyUserStatus.Deleted) {
        returnString = `${returnString} (Inactive)`
      }
      return returnString;
    } else {
      return 'N/A'
    }
  }

  private isInactiveUser(owner){
    return owner?.status === CompanyUserStatus.Disabled || owner?.status === CompanyUserStatus.Deleted
  }

  public hideBudgetCue(): void {
    this.showBudgetCue = false;
    this.cdRef.detectChanges();
    localStorage.setItem(this.budgetCueHiddenKey, 'true');
  }

  private resetState(): void {
    this.budgetTableRecordInteractions.resetSelection();
    this.budgetTableValidationService.resetState();
  }

  private onTableDataReady(): void {
    this.budgetTableContextMenuService.generateTableMenus(this.contextMenuHandlers);
    this.budgetTableContextMenuService.validateMenus(
      this.selectionState,
      this.budgetTableService.segmentsList
    );
    setTimeout(() => {
      this.handleSegmentNameAutoFocus();
      this.budgetTableValidationService.setExistingNames(this.tableData);
      this.loadingFinished$.next();
      this.cdRef.detectChanges();
    });
  }

  private handleSegmentNameAutoFocus(): void {
    if (!this.budgetTableService.objectToFocus) {
      return;
    }

    const targetCmp = this.segmentNameComponents.find((item) => (
      item.record.key === this.budgetTableService.objectToFocus
    ));

    if (targetCmp) {
      if (targetCmp.record?.type === BudgetTableRecordType.Group) {
        this.budgetTableRecordInteractions.handleToggleChange(targetCmp.record, true);
      }
      targetCmp.handleFocus();
      this.cdRef.detectChanges();
    }
    this.budgetTableService.setObjectToFocus(null);
  }

  private notifyRecordObjectChange(record: BudgetTableRecord, payload: SegmentGroup | BudgetSegment): void {
    if (record.type === BudgetTableRecordType.Group) {
      this.onChange.emit({
        actionType: BudgetTableActionType.GroupChange,
        payload
      });
      return;
    }

    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentChange,
      payload
    });
  }

  private getTargetSegment(record: BudgetTableRecord): BudgetSegment {
    return this.budgetTableService.segmentsList.find(bs => bs.key === record.key);
  }

  private getTargetSegmentAllocation(record: BudgetTableRecord, timeframe: BudgetTimeframe): BudgetSegmentAmount {
    const targetSegment = this.getTargetSegment(record);
    if (!targetSegment) {
      return null;
    }

    return targetSegment.amounts.find(alloc => alloc.budgetAllocationId === timeframe.id);
  }

  private resetDragOverAnimation(): void {
    cancelAnimationFrame(this.dragOverScrollAnimation);
    this.dragOverScrollDx = null;
  }

  public applySorting(columnName: string): void {
    this.budgetTableService.applySorting(columnName);
  }

  public handleOwnerChange(value: FlatSelectOption, record: BudgetTableRecord): void {
    try {
      const origin = this.budgetTableService.updateRecordOriginObject(record, { owner: value.id });
      this.budgetTableService.updateTableRecord(record, { owner: value });
      this.notifyRecordObjectChange(record, origin);
    } catch (e) {
      this.utilityService.handleError(
        new Error(this.ERROR_MESSAGE.FAILED_TO_UPDATE_OWNER)
      );
    }
  }

  public handleNameChange(name: string, record: BudgetTableRecord): void {
    try {
      const prevName = record.name;
      const origin = this.budgetTableService.updateRecordOriginObject(record, { name });
      this.budgetTableService.updateTableRecord(record, { name });
      this.budgetTableValidationService.updateExistingName(prevName, name, record.key);
      this.notifyRecordObjectChange(record, origin);
    } catch (e) {
      this.utilityService.handleError(
        new Error(this.ERROR_MESSAGE.FAILED_TO_UPDATE_NAME)
      );
    }
  }

  public handleAllocatedAmountChange(value: number, dataSource: BudgetTableGesturesDataSource): void {
    const { record, timeframe } = dataSource;
    const targetSegment = this.getTargetSegment(record);
    const targetAllocation = this.getTargetSegmentAllocation(record, timeframe);
    const prevValue = record.values.allocated[timeframe.id] || 0;
    const diffValue = value - prevValue;

    if (!targetAllocation) {
      this.utilityService.handleError(
        new Error(this.ERROR_MESSAGE.FAILED_TO_UPDATE_ALLOCATED)
      );
      return;
    }
    if (this.getForecastStatus && targetAllocation.amount === 0 && 
      (targetAllocation.forecastAmount === 0 || targetAllocation.forecastAmount === undefined)
    ) {
      this.handleForecastAmountChange(value, dataSource);
    }
    

    targetAllocation.amount = value;
    this.budgetTableService.updateTableRecordValues(record, {
      fields: {
        allocated: [timeframe.id],
        total: true
      },
      rawValue: value,
      diffValue
    });
    this.budgetTableService.updateGrandTotal({
      fields: {
        allocated: [timeframe.id],
        total: true
      },
      diffValue
    });
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentAmountChange,
      payload: targetSegment
    });
  }

  public handleForecastAmountChange(value: number, dataSource: BudgetTableGesturesDataSource): void {
    const { record, timeframe } = dataSource;
    const targetSegment = this.getTargetSegment(record);
    const targetAllocation = this.getTargetSegmentAllocation(record, timeframe);
    const prevValue = record.values.forecasted[timeframe.id] || 0;
    const diffValue = value - prevValue;

    if (!targetAllocation) {
      this.utilityService.handleError(
        new Error(this.ERROR_MESSAGE.FAILED_TO_UPDATE_ALLOCATED)
      );
      return;
    }

    targetAllocation.forecastAmount = value;
    this.budgetTableService.updateTableRecordValues(record, {
      fields: {
        forecasted: [timeframe.id],
        totalForecast: true
      },
      rawValue: value,
      diffValue
    });
    this.budgetTableService.updateGrandTotal({
      fields: {
        forecasted: [timeframe.id],
        totalForecast: true
      },
      diffValue
    });
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentAmountChange,
      payload: targetSegment
    });
  }

  public handleProjectedAmountChange(value: number, record: BudgetTableRecord): void {
    const prevValue = record.values.projected || 0;
    const diffValue = value - prevValue;
    const targetSegment = this.getTargetSegment(record);

    if (!targetSegment) {
      this.utilityService.handleError(
        new Error(this.ERROR_MESSAGE.FAILED_TO_UPDATE_PROJECTED)
      );
      return;
    }

    targetSegment.projectedAmount = value;
    this.budgetTableService.updateTableRecordValues(record, {
      fields: { projected: true },
      rawValue: value,
      diffValue
    });
    this.budgetTableService.updateGrandTotal({
      fields: { projected: true },
      diffValue
    });
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentChange,
      payload: targetSegment
    });
  }

  public handleOwnerSelectOpen(record: BudgetTableRecord): void {
    this.budgetPageService.refreshOwnerSelectOptions(record.id);
  }

  public handleTimeframeLockChange(value: boolean, timeframe: BudgetTimeframe): void {
    timeframe.locked = value;
    this.onChange.emit({
      actionType: BudgetTableActionType.TimeframeLockChange,
      payload: timeframe
    });
  }

  public handleSelection(value: MatCheckboxChange, record: BudgetTableRecord): void {
    if (record.type === BudgetTableRecordType.Group) {
      this.budgetTableRecordInteractions.handleToggleChange(record, true);
    }
    this.budgetTableRecordInteractions.handleSelection(value, record);
    this.onSelectionChanged.emit(this.budgetTableRecordInteractions.getSelectedRecords());
    this.budgetTableContextMenuService.validateMenus(
      this.selectionState,
      this.budgetTableService.segmentsList
    );
  }

  public handleToggleChange(value: boolean, record: BudgetTableRecord): void {
    this.budgetTableRecordInteractions.handleToggleChange(record, !value);
  }

  public handleToggleAllChange(value: boolean): void {
    this.budgetTableRecordInteractions.handleToggleAllChange(!value);
  }

  public handleDuplicateAction(): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentDuplicate,
      payload: this.budgetTableRecordInteractions.getSelectedRecords()
    });
    this.budgetTableRecordInteractions.resetSelection();
  }

  public handleDuplicateGroupAction(): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.GroupDuplicate,
      payload: this.budgetTableRecordInteractions.getSelectedRecords()
    });
    this.budgetTableRecordInteractions.resetSelection();
  }

  public handleMoveToGroupAction(targetGroup: SegmentGroup): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentsMove,
      payload: {
        selectedRecords: this.budgetTableRecordInteractions.getSelectedRecords(),
        targetGroup
      }
    });
    this.budgetTableRecordInteractions.resetSelection();
    this.segmentMenuTrigger?.closeMenu();
    this.groupMenuTrigger?.closeMenu();
  }

  public handleGroupSegmentsAction(): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentsGroup,
      payload: this.budgetTableRecordInteractions.getSelectedRecords()
    });
    this.budgetTableRecordInteractions.resetSelection();
    this.segmentMenuTrigger?.closeMenu();
    this.groupMenuTrigger?.closeMenu();
  }

  public handleUngroupSegmentsAction(): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentsUngroup,
      payload: this.budgetTableRecordInteractions.getSelectedRecords()
    });
    this.budgetTableRecordInteractions.resetSelection();
  }

  public handleDeleteAction(): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentDelete,
      payload: this.budgetTableRecordInteractions.getSelectedRecords()
    });
    this.budgetTableRecordInteractions.resetSelection();
  }

  public identifyRecord(index: number, item: BudgetTableRecord): string {
    return item.key;
  }

  public identifyTimeframe(index: number, item: BudgetTimeframe): number {
    return item.id;
  }

  public handleErrorState(error: string | null, fieldName: string, record: BudgetTableRecord): void {
    this.budgetTableValidationService.setFormDataError(error, fieldName, record);
  }

  public handleFocus($event: boolean, dataSource: BudgetTableGesturesDataSource): void {
    this.focusHandler.handleOnFocus($event, dataSource.record.values.allocated[dataSource.timeframe.id], this.getForecastStatus, this.budgetAllocationCell.nativeElement);
  }

  public handleDoubleClick($event: BudgetAllocationCellGesturesEvent, dataSource: BudgetTableGesturesDataSource): void {
    this.gesturesManager.executeAction(
       new BudgetAllocationTopupAction<BudgetTableGesturesDataSource>({
         actionTarget: {
           dataSource,
           ...$event
         },
         remainingBudget: this.remainingBudget,
         setAmount: this.handleAllocatedAmountChange.bind(this),
         getAmount: BudgetTableComponent.getDataSourceAmount,
         currency: this.currency?.symbol,
         decimalPipe: this.decimalPipe,
         compensateFromRemaining: true
       })
    );
  }

  public handleGlobalDragOver($event: DragEvent): void {
    const tableParent = this.tableParent?.nativeElement;
    if (!tableParent) {
      return;
    }

    const tableParentRect = tableParent.getBoundingClientRect();
    const relativeEventPosition = { x: $event.clientX - tableParentRect.left, y: $event.clientY - tableParentRect.top };
    const rightTriggerLimit = tableParent.clientWidth - (this.showProjected ? 288 : 144);
    const leftTriggerLimit = 425;
    const dxStep = 10;
    const prevDx = this.dragOverScrollDx;
    const step = () => {
      tableParent.scrollLeft = tableParent.scrollLeft + this.dragOverScrollDx;
      this.dragOverScrollAnimation = requestAnimationFrame(step);
    };

    if (relativeEventPosition.x < leftTriggerLimit) {
      this.dragOverScrollDx = -dxStep;
    } else if (relativeEventPosition.x > rightTriggerLimit) {
      this.dragOverScrollDx = +dxStep;
    } else {
      this.dragOverScrollDx = null;
    }

    if (prevDx !== this.dragOverScrollDx) {
      cancelAnimationFrame(this.dragOverScrollAnimation);
      this.dragOverScrollAnimation = requestAnimationFrame(step);
    }
  }

  public handleOnDragStart($event: BudgetAllocationCellGesturesEvent, dataSource: BudgetTableGesturesDataSource): void {
    this.resetDragOverAnimation();
    this.gesturesManager.trackDragStart(dataSource, $event);
  }

  public handleOnDragEnd(): void {
    this.gesturesManager.trackDragEnd();
    this.resetDragOverAnimation();
  }

  public handleOnDrop($event: BudgetAllocationCellGesturesEvent, dataSource: BudgetTableGesturesDataSource): void {
    this.resetDragOverAnimation();
    this.gesturesManager.trackDrop(dataSource, $event);
    this.gesturesManager.executeAction(
      new BudgetAllocationMoveAction<BudgetTableGesturesDataSource>({
        actionTarget: this.gesturesManager.getDndTargets(),
        setAmount: this.handleAllocatedAmountChange.bind(this),
        getAmount: BudgetTableComponent.getDataSourceAmount,
        currency: this.currency?.symbol,
        decimalPipe: this.decimalPipe,
      })
    );
  }

  public handleAddSegment(): void {
    this.onChange.emit({
      actionType: BudgetTableActionType.SegmentCreate,
      payload: null
    });
    this.budgetTableRecordInteractions.resetSelection();
    setTimeout(() => {
      this.handleSegmentNameAutoFocus();
    });
  }

  public handleAddGroupSegment(groupRecord: BudgetTableRecord): void {
    this.budgetTableRecordInteractions.handleToggleChange(groupRecord, true);
    this.onChange.emit({
      actionType: BudgetTableActionType.GroupSegmentCreate,
      payload: groupRecord
    });
    this.budgetTableRecordInteractions.resetSelection();
  }
}
